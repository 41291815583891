<template>
  <div>
    <b-card
      title="Password"
      class="mb-2"
    >
      <b-row>
        <b-col>
          <b-form-input v-model="password" />
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="ReCheck Orders By Date"
      class="mb-2"
    >
      <b-row>
        <b-col>
          <b-form-datepicker
            v-model="checkDate"
            menu-class="w-100"
            calendar-width="100%"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-button
            variant="primary"
            :disabled="!checkDate || checkDate === ''"
            @click="recheckPixByDate"
          >
            PIX
          </b-button>
          <b-button
            variant="primary"
            :disabled="!checkDate || checkDate === ''"
            class="ml-1"
            @click="recheckBoletoByDate"
          >
            Boleto
          </b-button>
          <b-button
            variant="primary"
            :disabled="!checkDate || checkDate === ''"
            class="ml-1"
            @click="recheckPicPayByDate"
          >
            PicPay
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Check & ReCheck"
      class="mb-2"
    >
      <b-row>
        <b-col>
          <b-form-input v-model="tradeNo" />
        </b-col>
        <b-col>
          <b-form-input v-model="orderCache" />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === ''"
            @click="recheckPixByOrder"
          >
            Recheck PIX
          </b-button>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === ''"
            class="ml-1"
            @click="recheckMachByOrder"
          >
            Recheck Mach
          </b-button>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === ''"
            class="ml-1"
            @click="recheckPayPalByOrder"
          >
            Recheck PayPal
          </b-button>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === ''"
            class="ml-1"
            @click="recheckPicPayByOrder"
          >
            Recheck PicPay
          </b-button>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === ''"
            class="ml-1"
            @click="recheckLevpayByOrder"
          >
            Recheck Levpay
          </b-button>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === ''"
            class="ml-1"
            @click="recheckLostOrder"
          >
            Recheck Losted
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === ''"
            @click="updateOrderInfo"
          >
            Update Order Info
          </b-button>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === '' || password === ''"
            class="ml-1"
            @click="resettlement"
          >
            Update Settlement
          </b-button>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === '' || password === ''"
            class="ml-1"
            @click="updatePaymentByOrder"
          >
            Update Payment By Order
          </b-button>
          <b-button
            variant="primary"
            :disabled="!fillOrderStatus || fillOrderStatus === '' || password === '' || !tradeNo || tradeNo === '' || password === ''"
            class="ml-1"
            @click="updateStatus"
          >
            Update Status
          </b-button>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === ''"
            class="ml-1"
            @click="resendEmail"
          >
            Resend Email
          </b-button>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === '' || password === ''"
            class="ml-1"
            @click="cancelOrder"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            :disabled="!tradeNo || tradeNo === '' || password === ''"
            class="ml-1"
            @click="cancelAndDelete"
          >
            Cancel & Delete
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Regenerate Bank Bills"
      class="mb-2"
    >
      <b-row>
        <b-col>
          <b-form-datepicker
            v-model="bankBillDate"
            menu-class="w-100"
            calendar-width="100%"
          />
        </b-col>
        <b-col>
          <v-select
            v-model="bankBillType"
            :options="bankBillTypeOptions"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-button
            variant="primary"
            :disabled="!bankBillDate || bankBillDate === ''"
            @click="recheckBankBill"
          >
            Regenerate Bank Bill
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Merchant Releted"
      class="mb-2"
    >
      <b-row>
        <b-col>
          <b-form-input v-model="reportMerchantNo" />
        </b-col>
        <b-col>
          <b-form-datepicker
            v-model="reportDate"
            menu-class="w-100"
            calendar-width="100%"
          />
        </b-col>
        <b-col>
          <b-form-datepicker
            v-model="reportDateEnd"
            menu-class="w-100"
            calendar-width="100%"
          />
        </b-col>
        <b-col>
          <date-picker
            v-model="dateRange"
            value-type="format"
            format="YYYY-MM-DD HH:mm:ss"
            type="datetime"
            range
            confirm
            :clearable="false"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-button
            variant="primary"
            :disabled="!reportDate || reportDate === '' || reportMerchantNo === '' || password === ''"
            @click="regenerateMerchantReport"
          >
            Regenerate Daily Report
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="!reportDate || reportDate === '' || !reportDateEnd || reportDateEnd === '' || reportMerchantNo === '' || password === ''"
            @click="regenerateMerchantSettlement"
          >
            Regenerate Settlement Report
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="reportMerchantNo === '' || password === ''"
            @click="regenerateHourlyReportByApp"
          >
            Regenerate APP Hourly Report
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="!reportDate || reportDate === '' || !reportDateEnd || reportDateEnd === '' || password === ''"
            @click="regenerateTrendReport"
          >
            Regenerate Trend Report
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Batch Operate"
      class="mb-2"
    >
      <b-row>
        <b-col>
          <b-form-input v-model="batchFillOrders" />
        </b-col>
        <b-col>
          <v-select
            v-model="fillOrderStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="fillOrderStatusOptions"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col>
          <b-form-checkbox v-model="callbackEnqueue">
            Enqueue
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-button
            variant="primary"
            :disabled="!fillOrderStatus || fillOrderStatus === '' || batchFillOrders === '' || password === ''"
            @click="executeBatchFill"
          >
            Execute Fill Orders
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="batchFillOrders === '' || password === ''"
            @click="executeBatchCallback"
          >
            Execute Callback
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="batchFillOrders === '' || password === ''"
            @click="fillRefunded"
          >
            Fill Refunded
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Export luxpag settlement"
      class="mb-2"
    >
      <b-row>
        <b-col>
          <b-form-datepicker
            v-model="startDateExport"
            menu-class="w-100"
            calendar-width="100%"
          />
        </b-col>
        <b-col>
          <b-form-datepicker
            v-model="endDateExport"
            menu-class="w-100"
            calendar-width="100%"
          />
        </b-col>
        <!-- <b-col>
          <b-form-input v-model="importSettlementsFromV1" />
        </b-col> -->
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <b-button
            variant="primary"
            :disabled="startDateExport === '' || endDateExport === ''"
            @click="exportLuxpagSettlement"
          >
            Start
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Import Sanctions"
      class="mb-2"
    >
      <b-row>
        <b-col
          md="4"
          class="mb-1"
        >
          <label>Type</label>
          <v-select
            v-model="typeFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col md="8" class="mb-1">
          <label>Select:</label>
          <b-form-file
            v-model="uploadFiles"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            multiple
          />
        </b-col>
        <b-col class="mb-1">
          <b-button variant="info" @click="upload">
            Upload
          </b-button>
        </b-col>
        <b-col md="12">
          <b-link v-for="file in imageFiles" :key="file" :href="file" target="_blank">
            {{ file }}
          </b-link>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BFormInput, BFormDatepicker, BFormCheckbox, BFormFile, BLink,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import useDevelopmentJs from './development'

const {
  recheckPixByDate,
  recheckBoletoByDate,
  recheckPicPayByDate,
  recheckPixByOrder,
  recheckMachByOrder,
  recheckPayPalByOrder,
  recheckPicPayByOrder,
  recheckLevpayByOrder,
  recheckLostOrder,
  recheckBankBill,
  updateOrderInfo,
  resettlement,
  updateStatus,
  resendEmail,
  cancelOrder,
  cancelAndDelete,
  regenerateMerchantReport,
  regenerateMerchantSettlement,
  regenerateHourlyReportByApp,
  executeBatchFill,
  executeBatchCallback,
  importOrderV1,
  importSettlementFromV1,
  exportLuxpagSettlement,
  updateOrderFromV1,
  updatePaymentByOrder,
  upload,
  regenerateTrendReport,
  fillRefunded,
} = useDevelopmentJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    BFormFile,
    BLink,
    DatePicker,
  },
  data() {
    return {
      password: '',
      uploadFiles: [],
      imageFiles: [],
      checkDate: '',
      tradeNo: '',
      orderCache: '',
      bankBillDate: '',
      bankBillType: 0,
      reportMerchantNo: '',
      reportDate: '',
      reportDateEnd: '',
      dateRange: [],
      startDate: '',
      endDate: '',
      startDateImport: '',
      endDateImport: '',
      startDateExport: '',
      endDateExport: '',
      batchFillOrders: '',
      updateOrdersFromV1: '',
      importSettlementsFromV1: '',
      fillOrderStatus: '',
      bankBillTypeOptions: [
        {
          label: 'BS2-Pagsmile-PIX',
          value: 0,
        },
        {
          label: 'BS2-Luxpag-PIX',
          value: 3,
        },
        {
          label: 'AME-WALLET',
          value: 1,
        },
        {
          label: 'BS2-Boleto',
          value: 2,
        },
        {
          label: 'BS2-Boleto-TS',
          value: 8,
        },
        {
          label: 'BRADESCO-PIX',
          value: 4,
        },
        {
          label: 'RENDIMENTO-PIX',
          value: 5,
        },
        {
          label: 'EtPay',
          value: 6,
        },
        {
          label: 'SPT-SPEI',
          value: 9,
        },
        {
          label: 'StarkBank-PIX',
          value: 10,
        },
      ],
      fillOrderStatusOptions: [
        {
          label: 'ChargeBack',
          value: 'ChargeBack',
        },
        {
          label: 'Success',
          value: 'Success',
        },
        {
          label: 'Refused',
          value: 'Refused',
        },
      ],
      callbackEnqueue: false,
      typeFilter: '1',
      typeOptions: [
        {
          label: 'SDN',
          value: '1',
        },
        {
          label: 'Non-SDN',
          value: '2',
        },
      ],
    }
  },
  methods: {
    recheckPixByDate() {
      recheckPixByDate(
        this.checkDate,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `recheck fail with ${fail}.`)
        },
      )
    },
    recheckBoletoByDate() {
      recheckBoletoByDate(
        this.checkDate,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `recheck fail with ${fail}.`)
        },
      )
    },
    recheckPicPayByDate() {
      recheckPicPayByDate(
        this.checkDate,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `recheck fail with ${fail}.`)
        },
      )
    },
    recheckPixByOrder() {
      const orders = this.tradeNo.split(',')
      recheckPixByOrder(
        JSON.parse(JSON.stringify(orders)),
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `PIX recheck fail with ${fail}.`)
        },
      )
    },
    recheckMachByOrder() {
      const orders = this.tradeNo.split(',')
      recheckMachByOrder(
        JSON.parse(JSON.stringify(orders)),
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `Mach recheck fail with ${fail}.`)
        },
      )
    },
    recheckPayPalByOrder() {
      const orders = this.tradeNo.split(',')
      recheckPayPalByOrder(
        JSON.parse(JSON.stringify(orders)),
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `Mach recheck fail with ${fail}.`)
        },
      )
    },
    recheckPicPayByOrder() {
      const orders = this.tradeNo.split(',')
      recheckPicPayByOrder(
        JSON.parse(JSON.stringify(orders)),
        success => {
          const result = JSON.stringify(success)
          showToast(this, 'Success', `${result}`)
        },
        fail => {
          showToast(this, 'Warning', `PicPay recheck fail with ${fail}.`)
        },
      )
    },
    recheckLevpayByOrder() {
      const orders = this.tradeNo.split(',')
      recheckLevpayByOrder(
        JSON.parse(JSON.stringify(orders)),
        success => {
          const result = JSON.stringify(success)
          showToast(this, 'Success', `${result}`)
        },
        fail => {
          showToast(this, 'Warning', `Levpay recheck fail with ${fail}.`)
        },
      )
    },
    recheckLostOrder() {
      const params = {
        trade_no: this.tradeNo,
        request_body: this.orderCache,
      }
      recheckLostOrder(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `recheck fail with ${fail}.`)
        },
      )
    },
    updateOrderInfo() {
      updateOrderInfo(
        this.tradeNo,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `updateOrderInfo fail with ${fail}.`)
        },
      )
    },
    resettlement() {
      const orders = this.tradeNo.split(',')
      const params = {
        password: this.password,
        data: JSON.parse(JSON.stringify(orders)),
      }
      resettlement(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `resettlement fail with ${fail}.`)
        },
      )
    },
    updateStatus() {
      const orders = this.tradeNo.split(',')
      const params = {
        password: this.password,
        tradeStatus: this.fillOrderStatus,
        data: JSON.parse(JSON.stringify(orders)),
      }
      updateStatus(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `updateStatus fail with ${fail}.`)
        },
      )
    },
    resendEmail() {
      const orders = this.tradeNo.split(',')
      resendEmail(
        JSON.parse(JSON.stringify(orders)),
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `resendEmail fail with ${fail}.`)
        },
      )
    },
    cancelOrder() {
      const orders = this.tradeNo.split(',')
      cancelOrder(
        JSON.parse(JSON.stringify(orders)),
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `cancel fail with ${fail}.`)
        },
      )
    },
    cancelAndDelete() {
      const orders = this.tradeNo.split(',')
      const params = {
        password: this.password,
        merchantNo: this.reportMerchantNo,
        data: orders,
      }
      cancelAndDelete(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `cancel fail with ${fail}.`)
        },
      )
    },
    recheckBankBill() {
      const params = {
        bankBillType: this.bankBillType,
        checkDate: this.bankBillDate,
      }
      recheckBankBill(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `fail with ${fail}.`)
        },
      )
    },
    regenerateMerchantReport() {
      const params = {
        password: this.password,
        reportMerchantNo: this.reportMerchantNo,
        checkDate: this.reportDate,
      }
      regenerateMerchantReport(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `Regenerate failed with ${fail}.`)
        },
      )
    },
    regenerateMerchantSettlement() {
      const params = {
        password: this.password,
        merchantNo: this.reportMerchantNo,
        start: this.reportDate,
        end: this.reportDateEnd,
      }
      regenerateMerchantSettlement(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `Regenerate failed with ${fail}.`)
        },
      )
    },
    regenerateHourlyReportByApp() {
      const [start, end] = this.dateRange
      if (!start || !end) {
        return
      }
      const params = {
        password: this.password,
        app_id: this.reportMerchantNo,
        start,
        end,
      }
      regenerateHourlyReportByApp(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `Regenerate failed with ${fail}.`)
        },
      )
    },
    regenerateTrendReport() {
      const params = {
        password: this.password,
        start: this.reportDate,
        end: this.reportDateEnd,
      }
      regenerateTrendReport(
        params,
        () => {
          showToast(this, 'Success', 'Submitted.')
        },
        fail => {
          showToast(this, 'Warning', `Regenerate failed with ${fail}.`)
        },
      )
    },
    executeBatchFill() {
      const orders = this.batchFillOrders.split(',')
      const params = {
        password: this.password,
        fillOrderStatus: this.fillOrderStatus,
        data: JSON.parse(JSON.stringify(orders)),
      }
      executeBatchFill(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `fail with ${fail}.`)
        },
      )
    },
    executeBatchCallback() {
      const orders = this.batchFillOrders.split(',')
      const params = {
        password: this.password,
        enqueue: this.callbackEnqueue,
        data: JSON.parse(JSON.stringify(orders)),
      }
      executeBatchCallback(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `fail with ${fail}.`)
        },
      )
    },
    importOrderV1() {
      const params = {
        start_time: this.startDate,
        end_time: this.endDate,
      }
      importOrderV1(
        params,
        () => {
          showToast(this, 'Success', 'Success.')
        },
        fail => {
          showToast(this, 'Warning', `Import failed with ${fail}.`)
        },
      )
    },
    updateOrderFromV1() {
      const orders = this.updateOrdersFromV1.split(',')
      updateOrderFromV1(
        JSON.parse(JSON.stringify(orders)),
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `Update failed. ${fail}`)
        },
      )
    },
    importSettlementFromV1() {
      // const orders = this.importSettlementFromV1.split(',')
      const params = {
        start_time: this.startDateImport,
        end_time: this.endDateImport,
        orders: this.importSettlementsFromV1.split(','),
      }
      importSettlementFromV1(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `Update failed. ${fail}`)
        },
      )
    },
    exportLuxpagSettlement() {
      const params = {
        start_time: this.startDateExport,
        end_time: this.endDateExport,
      }
      exportLuxpagSettlement(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `Update failed. ${fail}`)
        },
      )
    },
    updatePaymentByOrder() {
      const orders = this.tradeNo.split(',')
      updatePaymentByOrder(
        JSON.parse(JSON.stringify(orders)),
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `fail with ${fail}.`)
        },
      )
    },
    upload() {
      if (this.uploadFiles.length <= 0) {
        showToast(this, 'Warning', 'No File Select')
        return
      }
      const formData = new FormData()
      for (let i = 0; i < this.uploadFiles.length; i += 1) {
        const copyFile = new File([this.uploadFiles[i]], `${this.tradeNo}_${this.uploadFiles[i].name}`)
        formData.append('file', copyFile)
        formData.append('doc_type', this.typeFilter)
      }
      upload(
        formData,
        urls => {
          urls.urlList.forEach(item => {
            this.imageFiles.push(item)
          })
          this.imageFiles = Array.from(new Set(this.imageFiles))
        },
        fail => {
          showToast(this, 'Warning', `Upload proofs fail with ${fail}`)
        },
      )
    },
    fillRefunded() {
      const orders = this.batchFillOrders.split(',')
      fillRefunded(
        orders,
        () => {
          showToast(this, 'Success', 'Filled.')
        },
        fail => {
          showToast(this, 'Warning', `fail with ${fail}.`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker-range {
  width: 100% !important;
}

.mx-input {
  height: 37px;
}
</style>
